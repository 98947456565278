<template>
  <div>
    <XyzTransitionGroup
      appear
      class="item-group"
      xyz="fade left-3"
    >
      <b-card key="1">
        <XyzTransition
          appear
          xyz="fade up-3 delay-7"
        >
          <b-row class="mb-50">
            <b-col
              cols="12"
              xl="3"
              md="6"
              class="mb-1 pr-md-50 pr-lg-1"
            >
              <v-select
                v-model="filterQuotes.selectedSort"
                class="w-100"
                label="label"
                :options="sortOptions"
                placeholder="Sortir"
                :clearable="false"
                @option:selected="fetchQuote()"
              />
            </b-col>
            <b-col
              cols="12"
              xl="3"
              md="6"
              class="mb-1 pl-1 pl-md-50 pl-lg-0"
            >
              <b-form-input
                v-model="filterQuotes.search"
                class="w-100"
                placeholder="Cari data"
                debounce="500"
              />
            </b-col>
            <b-col
              cols="12"
              xl="5"
              md="12"
              class="d-flex ml-0 ml-xl-auto align-items-start mb-1"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-toggle.sidebar.sidebar-right
                variant="outline-primary"
                class="mr-1"
              >
                <feather-icon
                  icon="FilterIcon"
                  size="16"
                />
                Filter
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :to="{ name: 'addquote', params: { resetFormState: true } }"
                variant="primary"
                class="flex-grow-1"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="16"
                />
                Quote Baru
              </b-button>
            </b-col>
            <b-col
              v-if="filterMode"
              cols="12"
              class="mb-1"
            >
              <b-badge
                variant="primary"
                class="align-self-center mr-50 cursor-pointer"
                @click="resetFilter"
              >
                Reset Filter
                <feather-icon
                  icon="XIcon"
                />
              </b-badge>
            </b-col>
          </b-row>
        </XyzTransition>
        <XyzTransition
          appear
          xyz="fade down-3 delay-5"
        >
          <b-overlay :show="loading">
            <quotations-table
              :key="quotes.toString()"
              :data="quotes"
              :count="quotesCount"
              @refetch="fetchQuote"
            />
          </b-overlay>
        </XyzTransition>
      </b-card>
    </XyzTransitionGroup>

    <b-sidebar
      id="sidebar-right"
      ref="sidebarRight"
      title="Filter"
      right
      shadow
      backdrop
      backdrop-variant="transparent"
      class="sidebar"
      lazy
      @shown="fetchSomeStuff()"
      @hidden="fetchQuote()"
    >
      <div class="px-1 py-1">
        <label>Customer</label>
        <v-select
          v-model="filterQuotes.customer_id"
          class="w-100"
          label="name"
          :options="customers"
          placeholder="Cutomer"
          multiple
          @search="searchCustomer"
        >
          <template #no-options>
            <h6 class="text-muted font-weight-normal my-50">
              Cari customer...
            </h6>
          </template>
          <template #option="data">
            <span class="font-weight-bold d-block mt-25">{{ data.name }}</span>
            <small>
              <b-badge
                variant="light-info"
                class="mb-25"
              >
                {{ data.category }}
              </b-badge>
            </small>
          </template>
        </v-select>
        <div class="d-flex justify-content-between align-items-end">
          <label class="mt-1">Dibuat Tanggal</label>
          <feather-icon
            v-if="filterQuotes.created_at.startDate ? true : false"
            :key="key"
            size="16"
            icon="XCircleIcon"
            class="text-primary cursor-pointer"
            @click="filterQuotes.created_at = {}; $refs.dateRange.reset()"
          />
        </div>
        <date-range-picker
          ref="dateRange"
          v-model="filterQuotes.created_at"
          :wrap="true"
        />
        <label class="mt-1">Status</label>
        <v-select
          v-model="filterQuotes.status"
          class="w-100"
          label="label"
          :options="statusOptions"
          placeholder="Status"
          multiple
        />
        <label class="mt-1">Dibuat Oleh</label>
        <div
          v-if="loadingUser"
          class="d-flex"
        >
          <b-skeleton
            width="100%"
            height="40px"
            class="mb-0"
          />
        </div>
        <v-select
          v-else
          v-model="filterQuotes.userCreate"
          class="w-100"
          label="name"
          :options="user"
          placeholder="Dibuat Oleh"
          multiple
        >
          <template #option="data">
            <div class="d-flex">
              <b-avatar
                :text="avatarText(data.name)"
                variant="light-info"
                class="mr-50"
                size="36"
                :src="data.photo ? data.photo : null"
              />
              <div>
                <span class="font-weight-bold d-block">{{ data.name }}</span>
                <small class="d-block">{{ data.role.name }}</small>
              </div>
            </div>
          </template>
        </v-select>
        <div class="d-flex justify-content-between align-items-end">
          <label class="mt-1">Total</label>
          <feather-icon
            v-if="filterQuotes.total.min !== '' || filterQuotes.total.max !== ''"
            size="16"
            icon="XCircleIcon"
            class="text-primary cursor-pointer"
            @click="filterQuotes.total.min = ''; filterQuotes.total.max = ''"
          />
        </div>
        <b-row>
          <b-col
            cols="6"
            class="pr-50"
          >
            <label class="mt-25">Min</label>
            <cleave
              v-model="filterQuotes.total.min"
              class="form-control"
              :class="{ 'is-invalid' : isTotalError.error === 'min' }"
              :raw="true"
              placeholder="Min"
              :options="{ numeral: true }"
            />
          </b-col>
          <b-col
            cols="6"
            class="pl-50"
          >
            <label class="mt-25">Max</label>
            <cleave
              v-model="filterQuotes.total.max"
              class="form-control"
              :class="{ 'is-invalid' : isTotalError.error === 'max' }"
              :raw="true"
              placeholder="Max"
              :options="{ numeral: true }"
            />
          </b-col>
        </b-row>
        <small
          v-if="isTotalError.error"
          class="text-danger d-block mt-25"
        >{{ isTotalError.message }}</small>
        <label class="mt-1">Project</label>
        <v-select
          v-model="filterQuotes.project_id"
          class="w-100"
          label="name"
          :options="project"
          placeholder="Project"
          multiple
          @search="searchProject"
        >
          <template #no-options>
            <h6 class="text-muted font-weight-normal my-50">
              Cari project...
            </h6>
          </template>
          <template #option="data">
            <span class="font-weight-bold d-block mt-25">{{ data.name }}</span>
            <small class="text-muted">
              Tanggal Reminder : {{ formatDate(data.tgl_reminder) }}
            </small>
          </template>
        </v-select>
      </div>
      <template #footer>
        <div class="px-1 py-1">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="secondary"
            @click="resetFilter(); $refs.sidebarRight.hide()"
          >
            Reset
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BCard, BButton, BFormInput, BRow, BCol, VBToggle, BSidebar, BBadge, BOverlay, BSkeleton,
  BAvatar,
} from 'bootstrap-vue'
import QuotationsTable from '@/views/quotation/QuotationsTable.vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { createHelpers } from 'vuex-map-fields'
import DateRangePicker from '@/components/input/DateRangePicker.vue'
import Cleave from 'vue-cleave-component'
import { formatDate, avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { projects } from '@/graphql/query'

const { mapFields } = createHelpers({
  getterType: 'getQuotesField',
  mutationType: 'updateQuotesField',
})

export default {
  components: {
    BCard,
    BButton,
    BFormInput,
    QuotationsTable,
    BRow,
    BCol,
    BOverlay,
    BSidebar,
    BBadge,
    BSkeleton,
    BAvatar,
    vSelect,
    DateRangePicker,
    Cleave,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    filter: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      key: 0,
      formatDate,
      avatarText,
      statusOptions: [
        {
          id: 1,
          label: 'Draft',
          badge: 'light-info',
          variant: 'info',
        },
        {
          id: 2,
          label: 'Submitted',
          badge: 'light-warning',
          variant: 'warning',
        },
        {
          id: 3,
          label: 'Approved',
          badge: 'light-success',
          variant: 'success',
        },
        {
          id: 4,
          label: 'Rejected By Manager',
          badge: 'light-danger',
          variant: 'danger',
        },
        {
          id: 5,
          label: 'Rejected By Customer',
          badge: 'danger',
          variant: 'danger',
        },
        {
          id: 6,
          label: 'Closed',
          badge: 'light-secondary',
          variant: 'secondary',
        },
        {
          id: 7,
          label: 'Forward to SO',
          badge: 'dark',
          variant: 'dark',
        },
      ],
      project: [],
      customers: [],
    }
  },
  computed: {
    ...mapFields([
      'filterQuotes',
    ]),
    ...mapGetters({
      sortOptions: 'getSortOptionsQuote',
      quotes: 'getQuotesList',
      loading: 'getLoadingQuotes',
      quotesCount: 'getQuotesCount',
      customer: 'getCustomerList',
      user: 'getUserList',
      loadingUser: 'getLoadingUser',
    }),
    filterMode() {
      if (this.filterQuotes.search) return true
      if (this.filterQuotes.selectedSort.field !== 'updated_at' || this.filterQuotes.selectedSort.sortType !== 'desc') return true
      if (this.filterQuotes.customer_id.length) return true
      if (this.filterQuotes.created_at.startDate) return true
      if (this.filterQuotes.status.length) return true
      if (this.filterQuotes.userCreate.length) return true
      if (this.filterQuotes.total.min !== '' || this.filterQuotes.total.max !== '') return true
      if (this.filterQuotes.project_id.length) return true
      return false
    },
    isTotalError() {
      const min = +this.filterQuotes.total.min
      const max = +this.filterQuotes.total.max

      if (!min && !max) {
        return { error: false, message: '' }
      }

      if (max && min >= max) {
        return { error: 'max', message: 'Nominal max harus lebih besar' }
      }

      return { error: false, message: '' }
    },
  },
  watch: {
    filterQuotes: {
      handler() {
        if (!this.$refs.sidebarRight.isOpen) {
          this.fetchQuote()
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.filter) {
      this.filterQuotes = {
        ...this.filterQuotes,
        ...this.filter,
      }

      this.fetchQuote()

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Memfilter quote menggantung, reset filter untuk melihat semua.',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    } else {
      this.fetchQuote()
    }
  },
  methods: {
    fetchQuote() {
      const search = this.filterQuotes.search ? `"${this.filterQuotes.search}"` : null
      const customer = this.filterQuotes.customer_id.length ? `[${this.filterQuotes.customer_id.map(el => `"${el.id}"`).join(', ')}]` : null
      const status = this.filterQuotes.status.length ? `[${this.filterQuotes.status.map(el => el.id).join(', ')}]` : null
      const userCreate = this.filterQuotes.userCreate.length ? `[${this.filterQuotes.userCreate.map(el => el.id).join(', ')}]` : null
      const date = this.filterQuotes.created_at.startDate ? `{
        date_min: "${new Date(Date.parse(this.filterQuotes.created_at.startDate)).toISOString().slice(0, 10)}"
        date_max: "${new Date(Date.parse(this.filterQuotes.created_at.endDate)).toISOString().slice(0, 10)}"
      }` : null
      const total = +this.filterQuotes.total.min || +this.filterQuotes.total.max ? `{
        min: ${this.filterQuotes.total.min ? +this.filterQuotes.total.min : 0}
        max: ${this.filterQuotes.total.max ? +this.filterQuotes.total.max : 9999999999999}
      }` : null
      const project = this.filterQuotes.project_id.length ? `[${this.filterQuotes.project_id.map(el => el.id).join(', ')}]` : null

      this.$store.dispatch('getQuotes', {
        filter: `{
          customer_id: ${customer}
          status: ${status}
          created_at: ${date}
          userCreate: ${userCreate}
          total: ${total}
          sort: "${this.filterQuotes.selectedSort.field}"
          sortType: "${this.filterQuotes.selectedSort.sortType}"
          search: ${search}
          project_id: ${project}
        }`,
        pagination: `{
          limit: ${this.filterQuotes.limit}
          offset: ${this.filterQuotes.offset - 1},
        }`,
      })
    },
    async fetchSomeStuff() {
      if (this.user.length < 1) {
        this.$store.dispatch('getAllUser', { filter: null })
      }
    },
    resetFilter() {
      this.filterQuotes = {
        customer_id: [],
        created_at: {},
        status: [],
        search: '',
        userCreate: [],
        total: {
          min: '',
          max: '',
        },
        project_id: [],
        offset: 1,
        limit: 25,
        selectedSort: {
          label: 'Waktu Update ↑',
          field: 'updated_at',
          sortType: 'desc',
        },
      }

      this.fetchQuote()
    },
    searchCustomer(search, loading) {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }

      this.timeout = setTimeout(async () => {
        if (search.length) {
          loading(true)
          this.$store.dispatch('getCustomers', {
            filter: `{
              search: "${search}",
            }`,
            pagination: `{
              limit: 20,
              offset: 1,
            }`,
          }).then(result => {
            this.customers = result.customers
            loading(false)
          }).catch(() => {
            this.customers = []
            loading(false)
          })
        }
      }, 1000)
    },
    searchProject(search, loading) {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }

      this.timeout = setTimeout(async () => {
        if (search.length) {
          loading(true)
          this.$apollo.query({
            query: projects,
            context: {
              headers: {
                Authorization: this.$store.getters.getBearerToken,
              },
            },
            variables: {
              filter: {
                search,
              },
              pagination: {
                limit: 20,
              },
            },
            fetchPolicy: 'no-cache',
          }).then(result => {
            this.project = result.data.projects.projects
            loading(false)
          }).catch(() => {
            this.project = []
            loading(false)
          })
        }
      }, 1000)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/base/components/variables-dark';

.b-sidebar {
  .dark-layout & {
    background-color: $theme-dark-body-bg !important;
  }

  width: 360px;
  background-color: #fff !important;
}
</style>
